import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This is a compilation of the notes from week 1 of `}<a parentName="p" {...{
        "href": "https://courses.edx.org/courses/course-v1:HarvardX+CS50+X/"
      }}>{`CS50`}</a>{`. On the first week we start learning about C, we learn things such as Data Types, how to create variables and the `}<inlineCode parentName="p">{`void`}</inlineCode>{` type.`}</p>
    <h2 {...{
      "id": "create-variables",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#create-variables",
        "aria-label": "create variables permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Create variables`}</h2>
    <p>{`In C we need to specify the type of the variable and then give it a name. This is a piece of useful information to get out of the way straight away before diving deeper into the data types available.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`int number;`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`char letter;`}</inlineCode></li>
    </ul>
    <p>{`If you create multiple variables of the same type, you can specify the type and then the names of all the variables of those types.`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`int height, width;`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`float sqrt2, sqrt3, pi;`}</inlineCode></li>
    </ul>
    <p>{`After you have declared the data type of the variable, you don't need to specify it again when assigning in values to that variable.`}</p>
    <p>{`When creating a variable you can either declare a variable or initialize the variable. Seems, fancy but is just either declare the variable without assigning something to it or assigning a value to the variable.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-c"
      }}>{`int number; // declaration
int n = 17; // initialization
`}</code></pre>
    <h2 {...{
      "id": "int",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#int",
        "aria-label": "int permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Int`}</h2>
    <p>{`Is used to store integers, they will always take 4 bytes of memory (32 bits) - this means that the range of value that they can store if limited to 32 bits of information.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Range:`}</strong>{` −32,767 to +32,767`}</li>
      <li parentName="ul"><strong parentName="li">{`Format Specifier:`}</strong>{`  `}<inlineCode parentName="li">{`%hd`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "char",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#char",
        "aria-label": "char permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Char`}</h2>
    <p>{`Is used to store single characters, they will always take 1 byte of memory (8 bits) - this means the range of the value that can be store is limited to 8 bits of information.`}</p>
    <p>{`ASCII developed a mapping of characters (A, B, C...) to numeric values in the positive side of the `}<inlineCode parentName="p">{`char`}</inlineCode>{` range.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Range:`}</strong>{` -128 to 127`}</li>
      <li parentName="ul"><strong parentName="li">{`Format Specifier:`}</strong>{` `}<inlineCode parentName="li">{`%c`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "float",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#float",
        "aria-label": "float permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Float`}</h2>
    <p>{`Is used to store floating-point values (real numbers), they will take up to 4 bytes of memory (32 bits). When using floats we are limited to how precise that number can be`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Format Specifier:`}</strong>{` `}<inlineCode parentName="li">{`%f`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "double",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#double",
        "aria-label": "double permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Double`}</h2>
    <p>{`Is used to store floating-point values, but use double-precision, they will take up to 8 bytes of memory (64 bits). It allows us to get more precise real numbers`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Format Specifier:`}</strong>{` `}<inlineCode parentName="li">{`%lf`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "unsigned",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#unsigned",
        "aria-label": "unsigned permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unsigned`}</h2>
    <p>{`Unsigned isn't a data type but is a qualifier. It can double the positive range of a data type, at the cost of disallowing any negative values.`}</p>
    <p><strong parentName="p">{`Example:`}</strong>{` If we have an `}<inlineCode parentName="p">{`unsigned int`}</inlineCode>{` the range of that data type is: 0 to 65535 vs the regular int which is −32,767 to +32,76.`}</p>
    <p>{`Also, note that the format specifier for unsigned data types will be different than "regular" ones.`}</p>
    <h2 {...{
      "id": "void",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#void",
        "aria-label": "void permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Void`}</h2>
    <p>{`This isn't a data type, but a type. Functions can have a `}<inlineCode parentName="p">{`void`}</inlineCode>{` return type, which means they don't return a value. The parameter of a function can also be `}<inlineCode parentName="p">{`void`}</inlineCode>{`, which means that the function takes no parameters.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      